*, *::after, *::before {
  box-sizing: border-box;
  font-family: Arial;
  color: whitesmoke;
  fill: whitesmoke;
  --color-app:hsl(240, 3%, 7%);
  --color-wrong-location:hsl(49, 51%, 47%);
  --color-correct:hsl(115, 29%, 43%);
  --color-wrong:hsl(240, 2%, 23%);
  --color-active:hsl(200, 1%, 34%);
  --color-default:hsl(240, 2%, 23%);
}

body {
  background-color: var(--color-app);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 1em;
  font-size: clamp(.5rem, 2.5vmin, 1.5rem);
}

.keyboard {
  display: grid;
  grid-template-columns: repeat(20, minmax(auto, 2em));
  grid-auto-rows: 4em;
  gap: .25em;
  justify-content: center;
}

.keyButton {
  font-size: inherit;
  grid-column: span 2;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(
    var(--hue, 200),
    var(--saturation, 1%),
    calc(var(--lightness-offset, 0%) + var(--lightness, 51%))
  );
  text-transform: uppercase;
  border-radius: .25em;
  cursor: pointer;
  user-select: none;
}

.keyButton.large {
  grid-column: span 3;
}

.keyButton > svg {
  width: 1.75em;
  height: 1.75em;
}

.keyButton:hover, .keyButton:focus {
  --lightness-offset: 10%;
}

.keyButton[data-state="wrong"] {
  --lightness: 23%;
}

.keyButton[data-state="possible"] {
  --lightness: 40%;
}

.keyButton[data-state="wrong-location"] {
  --hue: 49;
  --saturation: 51%;
  --lightness: 47%;
}

.keyButton[data-state="correct"] {
  --hue: 115;
  --saturation: 29%;
  --lightness: 43%;
}

.guessGrid {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  gap: .25em;
  margin-bottom: 4em;
}

.tileRow {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  grid-template-columns: repeat(8, minmax(auto, 4em));
  grid-auto-rows: 4em;
  gap: .25em;
}

.tile {
  font-size: 2em;
  border: .05em solid var(--color-default);
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: transform 250ms linear;
}

.tile[shake="yes"] {
  animation: shake .5s ease-in-out;
}

.tile[flip="yes"] {
  transform: rotateX(90deg)
}

.tile[data-state="active"] {
  border-color: var(--color-active);
}

.tile[data-state="wrong"] {
  border: none;
  background-color: var(--color-wrong);
}

.tile[data-state="wrong-location"] {
  border: none;
  background-color: var(--color-wrong-location);
}

.tile[data-state="correct"] {
  border: none;
  background-color: var(--color-correct);
}

.tile[clicked-state="wrong"] {
  border: .1em solid var(--color-wrong);
  border-radius: 1em;
}

.tile[clicked-state="wrong-location"] {
  border: .1em solid var(--color-wrong-location);
  border-radius: 1em;
}

.tile[clicked-state="correct"] {
  border: .1em solid var(--color-correct);
  border-radius: 1em;
}

.cowBullTile {
  font-size: 2em;
  /* border: .1em solid var(--color-default); */
  border: none;
  border-radius: 1em;
  background-color: var(--color-default);
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: transform 250ms linear;
}

.cowBullTile[data-state="calc-zero"] {
  border: none;
  background-color: var(--color-wrong);
}

.cowBullTile[data-state="calc-cow"] {
  border: none;
  background-color: var(--color-wrong-location);
}

.cowBullTile[data-state="calc-bull"] {
  border: none;
  background-color: var(--color-correct);
}

.headerRow {
  display: grid;
  grid-template-columns: repeat(8, minmax(auto, 4em));
  grid-template-rows: repeat(1, minmax(auto, 4em));
  gap: .25em;
  justify-content: center;
  align-items: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  cursor: pointer;
  user-select: none;
  /* border: .05em solid red; */
}

.headerRow.heading {
  grid-column: span 5;
  padding: 0;
  display: flex;
  font-size:1.25em;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* border: .05em solid pink; */
}

.headerRow.space {
  padding: 0;
  display: flex;
  /* border: .05em solid pink; */
}

.headerRow.cowImg {
  padding: 0;
  border: 0.1em solid var(--color-wrong-location);
  border-radius: 1.5em;

  display: flex;
  font-size: 3em;

  /* background-image: url("./icons/cow-small.png");
  background-size: contain;
  background-repeat: no-repeat; */
}

.headerRow.bullImg {
  padding: 0;
  border: 0.1em solid var(--color-correct);
  border-radius: 1.5em;

  display: flex;
  font-size: 3em;

  /* background-image: url("./icons/bull-small.png");
  background-size: cover;
  background-repeat: no-repeat; */
}

.gameOver {
  display: grid;
  gap: .25em;
  justify-content: center;
  align-items: center;
  grid-template-rows: repeat(1, minmax(auto, 4em));
  text-align: center;
}

.practiceMode {
  display: flex;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 0.25em solid var(--color-active);

  min-width: 40em;
  max-width: 80em;
  min-height: 5em;
  max-height: 10em;
}

.alert-container {
  position: fixed;
  top: 100vh;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes shake {
  10% {
    transform: translateX(-5%);
  }

  30% {
    transform: translateX(5%);
  }

  50% {
    transform: translateX(-7.5%);
  }

  70% {
    transform: translateX(7.5%);
  }

  90% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes flip {
  25% {
    transform: rotateX(90deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  75% {
    transform: rotateX(270deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}